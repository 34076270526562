import React from "react";
// import Slider from "react-slick";
import "./styles/Projects.scss";
import Gamelist from "../media/images/gamelist.png";
import Mdi from "../media/images/mdi.png";
import Filestream from "../media/images/filestream.png";
// import Coviddashboard from "../media/images/covid-dashboard.png";
// import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { motion } from "framer-motion";
// import { Link } from "react-router-dom";
import { FaExternalLinkAlt } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa";
import ReactGA from "react-ga4";

const projectsData = [
  {
    title: "FileStream",
    description:
      "The GameList application is designed to allow users to see game information and add games to a list for later play if they enjoy them.",
    features: [
      "Context API was used to manage google auth and states in the application.",
      "Anyone can browse the details of the games, but to add them to a list, they must sign in using Google.",
      "Information about the games is sourced from the RAWG API.",
      "The user interface is created using React Material UI.",
      "I used the Material UI grid component to make the GameList application responsive.",
      "For animation, I used the Framer motion library.",
      "The game ID will be added to the Firestore by clicking the Add To List button, and it will be deleted from the Firestore by selecting Remove From List.",
      "On the list page, stored games can be viewed.",
    ],
    technologies: [
      "React JS",
      "Material UI",
      "Firebase",
      "Axios",
      "RAWG API",
      "Framer Motion",
    ],
    visitLink: "https://filestream.russeldsouza.dev/",
    githubLink: "https://github.com/RusselDsouza029/FileStream",
    imageSrc: Filestream,
  },
  {
    title: "GameList",
    description:
      "The GameList application is designed to allow users to see game information and add games to a list for later play if they enjoy them.",
    features: [
      "Context API was used to manage google auth and states in the application.",
      "Anyone can browse the details of the games, but to add them to a list, they must sign in using Google.",
      "Information about the games is sourced from the RAWG API.",
      "The user interface is created using React Material UI.",
      "I used the Material UI grid component to make the GameList application responsive.",
      "For animation, I used the Framer motion library.",
      "The game ID will be added to the Firestore by clicking the Add To List button, and it will be deleted from the Firestore by selecting Remove From List.",
      "On the list page, stored games can be viewed.",
    ],
    technologies: [
      "React JS",
      "Material UI",
      "Firebase",
      "Axios",
      "RAWG API",
      "Framer Motion",
    ],
    visitLink: "https://gamelist.russeldsouza.dev/",
    githubLink: "https://github.com/RusselDsouza029/gamelist",
    imageSrc: Gamelist,
  },
  {
    title: "MDI",
    description:
      "MDI Application created for giving information about movies and series.",
    features: [
      "For movie information, the TMDB(The Movie Database) API is used.",
      "Data were retrieved using the get method with the Axios Library.",
      "Cast and movie, series information, and images were obtained from various TMDB APIs.",
    ],
    technologies: ["React JS", "Material UI", "Firebase", "Axios", "TMDB API"],
    visitLink: "https://movie-data-info-647a7.web.app/",
    githubLink: "https://github.com/RusselDsouza029/mdi",
    imageSrc: Mdi,
  },
  // {
  //   title: "Indian Covid-19 Dashboard",
  //   description:
  //     "Indian Covid-19 Application created for getting information about Covid-19 all over India.",
  //   features: [
  //     "For UI Bootstrap is used.",
  //     "Covid-19 data is coming from a third-party API.",
  //   ],
  //   technologies: ["React JS", "Bootstrap", "Axios"],
  //   visitLink:
  //     "https://russeldsouza029.github.io/India-Covid-19-Dashboard-React/",
  //   imageSrc: Coviddashboard,
  // },
];
ReactGA.initialize("G-MKFBLQRXTM");

const handleClick = ({ link, projectName }) => {
  ReactGA.event({
    category: "Project Clicks",
    action: projectName,
    label: link,
  });
};

const ProjectsV1 = ({ textEnter, textLeave }) => {
  // var settings = {
  //   dots: false,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   nextArrow: <AiOutlineRight />,
  //   prevArrow: <AiOutlineLeft />,
  //   adaptiveHeight: true,
  //   responsive: [
  //     {
  //       breakpoint: 1024, // Tablet breakpoint
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //         dots: false,
  //         arrows: false,
  //       },
  //     },
  //     {
  //       breakpoint: 768, // Mobile breakpoint
  //       settings: "unslick", // Disable the carousel
  //     },
  //   ],
  // };

  return (
    <motion.div
      id="projects"
      className="projects--container"
      viewport={{ once: true }}
      initial="hidden"
      whileInView="visible"
      transition={{ duration: 1, delay: 1 }}
      variants={{
        visible: { opacity: 1, y: 0, visibility: "visible" },
        hidden: { opacity: 0, y: 50, visibility: "hidden" },
      }}
    >
      <div className="projects__parent">
        <div
          className="projects__content projects__left"
          onMouseEnter={textEnter}
          onMouseLeave={textLeave}
        >
          <div className="sm-heading">
            <h2>Projects</h2>
            <span></span>
          </div>
          <p>
            I have created a collection of responsive web projects using HTML,
            CSS, JavaScript, and React JS. The projects are hosted on the
            Firebase platform and are accessible on a variety of devices. I am
            proud of my work on these projects and believe they showcase my
            skills as a web developer. I am confident that I can use my skills
            to create even more impressive projects in the future. I have used
            flexbox and CSS grid to create fluid layouts that adapt to different
            screen sizes. I have also used media queries to style my projects
            differently for different screen sizes.
          </p>
        </div>
        <div
          className="projects__content projects__right"
          onMouseEnter={textEnter}
          onMouseLeave={textLeave}
        >
          {projectsData.map((items, ind) => (
            <div className="dv-projects-container" key={ind}>
              <div className="dv-project-img">
                <img src={items.imageSrc} alt={items.title} />
              </div>
              <div className="dv-project-info">
                <a
                  href={items.visitLink}
                  className="a-btn"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() =>
                    handleClick({
                      link: items.visitLink,
                      projectName: items.title,
                    })
                  }
                >
                  <FaExternalLinkAlt />
                </a>
                <a
                  href={items.githubLink}
                  className="a-btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaGithub />
                </a>
                {/* <a
                  href={items.githubLink}
                  className="a-btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaRegEye />
                </a> */}
              </div>
            </div>
          ))}
          {/* <Slider {...settings}>
            {projectsData.map((project, index) => (
              <div key={index} className="slide__content">
                <div className="slide__img">
                  <img src={project.imageSrc} alt={project.title} />
                </div>
                <div className="slide__content__text">
                  <h3>{project.title}</h3>
                  <p>{project.description}</p>
                  <ul>
                    {project.features.map((feature, i) => (
                      <li key={i}>{feature}</li>
                    ))}
                  </ul>
                  <ul className="list__tech__used">
                    {project.technologies.map((tech, i) => (
                      <li key={i}>{tech}</li>
                    ))}
                  </ul>
                  <div className="visit__btn">
                    <a
                      href={project.visitLink}
                      target="_blank"
                      className="a-btn"
                      rel="noreferrer"
                    >
                      Visit <AiOutlineRight />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </Slider> */}
        </div>
      </div>
    </motion.div>
  );
};

export default ProjectsV1;

// list__tech__used
