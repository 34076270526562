import React from "react";
import { HiOutlineDocumentText } from "react-icons/hi";
import "./styles/Heading.css";
import { motion } from "framer-motion";

const headingTexts = [
  "Hi, My Name is",
  "Russel Dsouza.",
  "I build web applications.",
];

const Heading = ({ textEnter, textLeave }) => {
  return (
    <div className="name-heading">
      <motion.div
        viewport={{ once: true }}
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 1, delay: 0.5 }}
        variants={{
          visible: { opacity: 1, y: 0, visibility: "visible" },
          hidden: { opacity: 0, y: 50, visibility: "hidden" },
        }}
        className="heading-parent"
      >
        {headingTexts.map((text, index) => (
          <div
            key={index}
            className={index === 0 ? "sm-heading" : "big-heading"}
          >
            {index === 0 ? (
              <>
                {text}
                <span></span>
              </>
            ) : (
              <>
                <h1 onMouseEnter={textEnter} onMouseLeave={textLeave}>
                  {text}
                </h1>
                {/* <h2>I build web applications.</h2> */}
              </>
            )}
          </div>
        ))}
        <p onMouseEnter={textEnter} onMouseLeave={textLeave}>
          Front End Developer / React JS Developer
        </p>
        <div>
          <a
            onMouseEnter={textEnter}
            onMouseLeave={textLeave}
            className="a-btn"
            href="https://firebasestorage.googleapis.com/v0/b/russel-portfolio.appspot.com/o/russel-dsouza.pdf?alt=media&token=2a228227-2baa-449a-80af-b3787cbbfce3"
            target="_blank"
            rel="noreferrer"
          >
            Resume
            <HiOutlineDocumentText />
          </a>
        </div>
      </motion.div>
    </div>
  );
};

export default Heading;
