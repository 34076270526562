import { createContext, useContext, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { motion } from "framer-motion";

const AppCreateContext = createContext();

const AppProvideContext = ({ children }) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [cursorVariants, setCursorVariants] = useState("default");

  const variants = {
    default: {
      x: mousePosition.x - 15,
      y: mousePosition.y - 15,
    },
    text: {
      height: 60,
      width: 60,
      x: mousePosition.x - 30,
      y: mousePosition.y - 30,
      mixBlendMode: "difference",
    },
  };

  useEffect(() => {
    ReactGA.initialize("G-MKFBLQRXTM");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    const mouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener("mousemove", mouseMove);

    return () => {
      window.removeEventListener("mousemove", mouseMove);
    };
  }, []);

  const textEnter = () => setCursorVariants("text");
  const textLeave = () => setCursorVariants("default");
  return (
    <AppCreateContext.Provider
      value={{ variants, cursorVariants, textEnter, textLeave }}
    >
      {/* <motion.div variants={variants} animate={cursorVariants}> */}
      {children}
      {/* </motion.div> */}
    </AppCreateContext.Provider>
  );
};

// export const AppUseContext = useContext(AppCreateContext);

export function AppUseContext() {
  useContext(AppCreateContext);
}

export default AppProvideContext;
