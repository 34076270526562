import React, { useEffect, useState } from "react";
import { HiOutlineDocumentText, HiOutlineMail } from "react-icons/hi";
import "./styles/Nav.css";
import { HashLink } from "react-router-hash-link";
import { motion, useScroll, useSpring } from "framer-motion";

const Nav = ({ textEnter, textLeave }) => {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  const [menuStyles, setMenuStyles] = useState({
    firstBar: { rotate: "0deg" },
    secondBar: { marginTop: "10px", rotate: "0deg", width: "30px" },
    menuSetting: { top: "0px", visibility: "visible", opacity: 1, zIndex: 1 },
    navStyle: { backgroundColor: "#0c0c0c" },
  });

  const [showCloseBg, setShowCloseBg] = useState(false);

  const trigerMenu = () => {
    if (menuStyles.secondBar.marginTop === "10px") {
      setMenuStyles({
        firstBar: { rotate: "-45deg" },
        secondBar: { marginTop: "0px", width: "40px", rotate: "45deg" },
        menuSetting: { top: "60px", visibility: "visible", opacity: 1 },
        navStyle: { backgroundColor: "#1f1e43" },
      });
      setShowCloseBg(true);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
      setShowCloseBg(false);
      setMenuStyles({
        firstBar: { rotate: "0deg" },
        secondBar: { marginTop: "10px", width: "30px", rotate: "0deg" },
        menuSetting: { top: "-500px", visibility: "hidden", opacity: 0 },
        navStyle: { backgroundColor: "#1f1e43" },
      });
    }
  };

  const closeMenuForLargeScreen = () => {
    document.body.style.overflow = "auto";
    setShowCloseBg(false);
    setMenuStyles({
      firstBar: { rotate: "0deg" },
      secondBar: { marginTop: "10px", width: "30px", rotate: "0deg" },
      menuSetting: { top: "-500px", visibility: "hidden", opacity: 0 },
      navStyle: { backgroundColor: "#1f1e43" },
    });
  };

  const changeMediaQueryResponsive = (mq) => {
    if (mq.matches) {
      setMenuStyles({
        ...menuStyles,
        menuSetting: {
          top: "-500px",
          visibility: "hidden",
          opacity: 0,
          zIndex: -1,
        },
      });
    } else {
      setMenuStyles({
        ...menuStyles,
        menuSetting: {
          top: "0px",
          visibility: "visible",
          opacity: 1,
          zIndex: 1,
        },
      });
    }
  };

  const [storeMediaQueryVar, setStoreMediaQueryVar] = useState();

  useEffect(() => {
    let mq = window.matchMedia("(max-width: 800px)");
    changeMediaQueryResponsive(mq);
    mq.addListener(changeMediaQueryResponsive);
    setStoreMediaQueryVar(mq);
    // eslint-disable-next-line
  }, []);

  const [changeNavBoxShadow, setChangeBoxShadow] = useState(
    "0px 0px transparent"
  );

  useEffect(() => {
    const winScroll = () => {
      if (document.documentElement.scrollTop > 10) {
        setChangeBoxShadow("2px -9px 20px 0px #000000fc");
      } else {
        setChangeBoxShadow("0px 0px transparent");
      }
    };
    window.addEventListener("scroll", winScroll);
  }, []);

  return (
    <>
      {showCloseBg ? (
        <div onClick={trigerMenu} className="bg-close-menu"></div>
      ) : null}
      <motion.div className="progress-bar" style={{ scaleX }} />
      <nav style={{ boxShadow: changeNavBoxShadow }}>
        <div>
          <a href="/">
            <motion.div
              className="logo"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
              onMouseEnter={textEnter}
              onMouseLeave={textLeave}
            >
              R
            </motion.div>
          </a>
        </div>
        <div>
          <div className="bar-parent" onClick={trigerMenu}>
            <div
              className="bar-1 bar-bg"
              style={{ transform: `rotate(${menuStyles.firstBar.rotate})` }}
            ></div>
            <div
              className="bar-2 bar-bg"
              style={{
                width: menuStyles.secondBar.width,
                transform: `rotate(${menuStyles.secondBar.rotate})`,
                marginTop: menuStyles.secondBar.marginTop,
              }}
            ></div>
          </div>
          <div
            className="div-links"
            style={{
              top: menuStyles.menuSetting.top,
              visibility: menuStyles.menuSetting.visibility,
              opacity: menuStyles.menuSetting.opacity,
              zIndex: menuStyles.menuSetting.zIndex,
            }}
          >
            <ul className="ul-links">
              {["About", "Experience", "Projects", "Contact"].map(
                (section, index) => (
                  <motion.li
                    key={index}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 1, delay: 0.2 * index }}
                    variants={{
                      visible: { opacity: 1, x: 0 },
                      hidden: { opacity: 0, x: 100 },
                    }}
                  >
                    <HashLink
                      smooth
                      to={`/#${section.toLowerCase()}`}
                      onMouseEnter={textEnter}
                      onMouseLeave={textLeave}
                      onClick={() => {
                        if (storeMediaQueryVar.matches) {
                          closeMenuForLargeScreen();
                        }
                      }}
                    >
                      {section}
                    </HashLink>
                  </motion.li>
                )
              )}
            </ul>
            <div className="contact-and-resume-btn">
              {/* <motion.a
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 1, delay: 0.8 }}
                variants={{
                  visible: { opacity: 1, x: 0 },
                  hidden: { opacity: 0, x: 100 },
                }}
                className="a-btn"
                href="mailto:russeldsouza456@gmail.com"
                target="_blank"
                rel="noreferrer"
                onMouseEnter={textEnter}
                onMouseLeave={textLeave}
              >
                Contact
                <HiOutlineMail />
              </motion.a> */}
              <motion.a
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 1, delay: 1 }}
                variants={{
                  visible: { opacity: 1, x: 0 },
                  hidden: { opacity: 0, x: 100 },
                }}
                className="a-btn"
                href="https://firebasestorage.googleapis.com/v0/b/russel-portfolio.appspot.com/o/russel-dsouza.pdf?alt=media&token=2a228227-2baa-449a-80af-b3787cbbfce3"
                target="_blank"
                rel="noreferrer"
                onMouseEnter={textEnter}
                onMouseLeave={textLeave}
              >
                Resume
                <HiOutlineDocumentText />
              </motion.a>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Nav;
