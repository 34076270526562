import React from "react";
import "./styles/Footer.css";
// import { SlSocialTwitter } from "react-icons/sl";
import { FaWhatsapp } from "react-icons/fa";
import { FiPhoneCall, FiMail, FiGithub } from "react-icons/fi";
import { AiOutlineLinkedin } from "react-icons/ai";
import { motion } from "framer-motion";

const socialMediaLinks = [
  { icon: <FiGithub />, href: "https://github.com/RusselDsouza029" },
  { icon: <AiOutlineLinkedin />, href: "https://www.linkedin.com/in/russel-dsouza-7aa065231/" },
  { icon: <FaWhatsapp />, href: "https://wa.me/919284113175" },
  { icon: <FiPhoneCall />, href: "tel:+919284113175" },
  { icon: <FiMail />, href: "mailto:russeldsouza456@gmail.com" },
];

const Footer = ({ textEnter, textLeave }) => {
  return (
    <footer className="footer">
      <div
        onMouseEnter={textEnter}
        onMouseLeave={textLeave}
        className="foot-social-media"
      >
        {socialMediaLinks.map((link, index) => (
          <motion.a
            key={index}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1, delay: index * 0.2 + 0.3 }}
            variants={{
              visible: { opacity: 1, y: 0, visibility: "visible" },
              hidden: { opacity: 0, y: 50, visibility: "hidden" },
            }}
            href={link.href}
            target="_blank"
            rel="noreferrer"
            className="a-social-media"
          >
            {link.icon}
          </motion.a>
        ))}
      </div>
    </footer>
  );
};

export default Footer;
