import React, { useEffect, useState } from "react";
import { Routes, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
// import ReactGA from "react-ga";
import "./App.css";
import About from "./Components/About";
import Nav from "./Components/Nav";
import Heading from "./Components/Heading";
import ExperienceV1 from "./Components/ExperienceV1";
import ProjectsV1 from "./Components/ProjectsV1";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import { Route } from "react-router-dom";
import Home from "./Components/Home";
// import ReactGA from "react-ga4";

function App() {
  // ReactGA.initialize("G-MKFBLQRXTM");
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [cursorVariants, setCursorVariants] = useState("default");

  const variants = {
    default: {
      x: mousePosition.x - 15,
      y: mousePosition.y - 15,
    },
    text: {
      height: 60,
      width: 60,
      x: mousePosition.x - 30,
      y: mousePosition.y - 30,
      mixBlendMode: "difference",
    },
  };

  const location = useLocation();

  // useEffect(() => {
  //   ReactGA.set({ page: location.pathname });
  //   ReactGA.send({ hitType: "pageview", page: location.pathname });
  // }, [location]);

  useEffect(() => {
    const mouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener("mousemove", mouseMove);

    return () => {
      window.removeEventListener("mousemove", mouseMove);
    };
  }, []);

  const textEnter = () => setCursorVariants("text");
  const textLeave = () => setCursorVariants("default");

  return (
    <>
      <motion.div
        className="cursor"
        variants={variants}
        animate={cursorVariants}
      />
      <div className="App">
        <Nav textEnter={textEnter} textLeave={textLeave} />
        <Routes>
          <Route
            path="/"
            element={<Home textEnter={textEnter} textLeave={textLeave} />}
          />
          {/* <Heading textEnter={textEnter} textLeave={textLeave} />
            <About textEnter={textEnter} textLeave={textLeave} />
            <ExperienceV1 textEnter={textEnter} textLeave={textLeave} />
            <ProjectsV1 textEnter={textEnter} textLeave={textLeave} />
  <Contact textEnter={textEnter} textLeave={textLeave} /> */}
        </Routes>
        <Footer textEnter={textEnter} textLeave={textLeave} />
      </div>
    </>
  );
}

export default App;
