import React from "react";
import Heading from "./Heading";
import About from "./About";
import ExperienceV1 from "./ExperienceV1";
import ProjectsV1 from "./ProjectsV1";
import Contact from "./Contact";

const Home = ({ textEnter, textLeave }) => {
  return (
    <>
      <Heading textEnter={textEnter} textLeave={textLeave} />
      <About textEnter={textEnter} textLeave={textLeave} />
      <ExperienceV1 textEnter={textEnter} textLeave={textLeave} />
      <ProjectsV1 textEnter={textEnter} textLeave={textLeave} />
      <Contact textEnter={textEnter} textLeave={textLeave} />
    </>
  );
};

export default Home;
