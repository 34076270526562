import React from "react";
import "./styles/About.css";
import img from "../media/images/img.jpg";
import { motion } from "framer-motion";
import { FaHtml5, FaCss3Alt, FaBootstrap, FaGithub } from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io5";
import { RiReactjsFill } from "react-icons/ri";
import { TbBrandSass } from "react-icons/tb";
import { FiFramer } from "react-icons/fi";
import { IoLogoFirebase } from "react-icons/io5";
import { SiMaterialui, SiSupabase } from "react-icons/si";
import Marquee from "react-fast-marquee";

const About = ({ textEnter, textLeave }) => {
  const textMotionProps = {
    viewport: { once: true },
    initial: "hidden",
    whileInView: "visible",
    transition: { duration: 1, delay: 1 },
    variants: {
      visible: { opacity: 1, y: 0, visibility: "visible" },
      hidden: { opacity: 0, y: 50, visibility: "hidden" },
    },
  };

  const skillsObj = [
    {
      icons: <FaHtml5 />,
    },
    {
      icons: <FaCss3Alt />,
    },
    {
      icons: <IoLogoJavascript />,
    },
    {
      icons: <RiReactjsFill />,
    },
    {
      icons: <FaBootstrap />,
    },
    {
      icons: <SiMaterialui />,
    },
    {
      icons: <FiFramer />,
    },
    {
      icons: <IoLogoFirebase />,
    },
    {
      icons: <FaGithub />,
    },
    {
      icons: <TbBrandSass />,
    },
    {
      icons: <SiSupabase />,
    },
  ];

  var mobile = window.matchMedia("(max-width: 500px)");

  return (
    <div className="about-parent-div" id="about">
      <div className="about-conatiner">
        <motion.div {...textMotionProps} className="about-text atb-content">
          <div>
            <div
              className="sm-heading"
              onMouseEnter={textEnter}
              onMouseLeave={textLeave}
            >
              <h2>About Me</h2>
              <span></span>
            </div>
            <div
              className="about-info"
              onMouseEnter={textEnter}
              onMouseLeave={textLeave}
            >
              <p>
                Hello, I'm Russel Dsouza, and I have a passion for crafting web
                applications. During my college years, I took the initiative to
                delve into web development independently. Subsequently, I
                further honed my skills by enrolling in a Front End Development
                certification course at TryCatch Classes. Currently, I'm working
                as a web developer at Futuready Media, where I actively
                contribute to the creation of engaging and innovative websites.
              </p>
              <p>
                Below are few technologies I&rsquo;ve been working with
                recently:
              </p>
              {mobile.matches ? (
                <div className="skills-container">
                  {skillsObj.map((icon, ind) => (
                    <div className="skills-box" key={ind}>
                      {icon.icons}
                    </div>
                  ))}
                </div>
              ) : (
                <Marquee pauseOnHover={true} style={{ padding: 10 }}>
                  <div className="skills-container">
                    {skillsObj.map((icon, ind) => (
                      <div
                        className="skills-box"
                        key={ind}
                        style={{
                          margin: 10,
                        }}
                      >
                        {icon.icons}
                      </div>
                    ))}
                  </div>
                </Marquee>
              )}
              {/* <ul className="ul-skills-list">
                <li>HTML5</li>
                <li>CSS3</li>
                <li>JavaScript (ES6+)</li>
                <li>React JS</li>
                <li>Bootstrap</li>
                <li>Material UI</li>
                <li>Framer Motion</li>
                <li>Firebase</li>
                <li>Netlify</li>
                <li>GitHub</li>
                <li>SCSS</li>
              </ul> */}
            </div>
          </div>
        </motion.div>
        <motion.div {...textMotionProps} className="my-img atb-content">
          <img src={img} alt="Russel Dsouza" width="200" />
        </motion.div>
      </div>
    </div>
  );
};

export default About;
